<template>
  <div class="mt-5 success-holder">
    <!-- show when user use standard and want to choose upgrade plan or click on thanks -->
    <b-container>
      <div v-if="hasCompleted === 0">
        <div class="success-card">
          <div class="margin-bottom-24">
            <div class="d-flex flex-column align-center py-16">
              <img src="@/assets/img/reservation-success.svg" class="mv-4 mb-4" />
              <h4 class="reserve-success-text">
                {{ $t("Reservation.ReservationSuccessfully") }}
              </h4>
              <h3 class="reserve-success-sub-text">{{ $t("ListingProcess.ReservationSuccessfullyDesc") }}</h3>
            </div>
          </div>
        </div>

        <div class="my-10">
          <TheListingUpgrade :fee="getAmount" :changed="changed" />
        </div>
      </div>
      <b-card  v-if="!paid && hasCompleted === 2" class="border p-3 my-3">
        <VCreditCard @change="creditInfoChanged" :trans="creditTranslations" />
        <div style="width: 400px;padding: 0 20px;margin: 0 auto">
          <v-btn
            @click="payListingFee()"
            :disabled="payable"
            class="px-5"
            rounded
            block
            :loading="payLoading"
            depressed
            color="primary"
          >
            <b>{{ $t("ListingProcess.Pay") }} ${{ getAmount }} {{ $t("ListingProcess.Now") }}</b>
          </v-btn>
        </div>
      </b-card>
      <div v-if="(paid && hasCompleted === 2) || (hasCompleted === 3) || (hasCompleted === 1)">
        <div class="success-card">
          <div class="margin-bottom-24">
            <div class="d-flex flex-column align-center py-16">
              <img src="@/assets/img/reservation-success.svg" class="mv-4 mb-4" />
              <h4 class="reserve-success-text">
                {{ $t("Reservation.ReservationSuccessfully") }}
              </h4>
              <h3 class="reserve-success-sub-text">{{ $t("ListingProcess.ReservationSuccessfullyDesc") }}</h3>
            </div>
          </div>
        </div>
        <b-alert
          show
          variant="primary"
          class="alert-box info-box border-0 pay-alert"
          v-if="hasCompleted !== 1"
        >
          <div><h5>{{ $t("ListingProcess.ThankYou") }}</h5></div>
          <p>{{ $t("ListingProcess.ReservationSuccessfullyMessage") }}</p>
        </b-alert>
        <b-alert
          show
          variant="primary"
          class="alert-box info-box border-0 pay-alert"
          v-else-if="hasCompleted === 1"
        >
          <p v-html='$t("ListingProcess.ReservationSuccessfullyMessageNoPay")'></p>
        </b-alert>

      </div>
    </b-container>
    <div v-if="hasCompleted === 1 || hasCompleted === 0 || hasCompleted === 3 || paid && hasCompleted === 2" class="success-buttons-holder">
      <b-container>
        <div class="success-buttons">
            <b-button :to="{ name: 'SellerDashboard'}">{{ $t("ListingProcess.ViewYourDashboard") }}</b-button>
            <b-button :to="{ name: 'MyListings'}">{{ $t("ListingProcess.ViewYourListing") }}</b-button>
            <b-button :to="{ name: 'listingOption'}">{{ $t("ListingProcess.ListAnotherVehicle") }}</b-button>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VCreditCard from "v-credit-card";
import "v-credit-card/dist/VCreditCard.css";

export default {
  components: {
    VCreditCard,
    TheListingUpgrade: () =>
      import("@/components/listing/TheListingUpgrade.vue")
  },
  data() {
    return {
      completed: false,
      name: null,
      cardNumber: null,
      expiration: null,
      security: null,
      paid: false,
      plans: [],
      listingStockID: false,
      payLoading: false,
      creditTranslations: {
        name: {
          label: this.$t("Reservation.CreditCardName"),
          placeholder: this.$t("Reservation.CreditCardNamePlaceHolder"),
        },
        card: {
          label: this.$t("Reservation.CreditCard"),
          placeholder: this.$t("Reservation.CreditCardPlaceHolder"),
        },
        expiration: {
          label: this.$t("Reservation.CreditCardExpiration"),
        },
        security: {
          label: this.$t("Reservation.CreditCardSecurity"),
          placeholder: this.$t("Reservation.CreditCardSecurityPlaceHolder"),
        },
      },
    };
  },
  methods: {
    async sendID() {
      try {
        const response = await axios.post("Listing/PaymentComplete", {
          Id: this.$route.query.id
        });
        if (response.data === 0) this.$router.push({ name: "Home" });
      } catch (error) {
        console.log(error.response.data);
      }
    },
    async listingComplete() {
      try {
        await axios.post("Listing/ListingDone", {
          ListingId: this.$store.getters.listingStockID
        });
      } catch (error) {
        console.log(error.data);
      }
    },
    changed() {
      this.completed = true;
    },
    creditInfoChanged(values) {
      for (const key in values) {
        this[key] = values[key];
      }
    },
    async payListingFee() {
      try {
        this.payLoading = true;
        await axios.post("Listing/Payment", {
          ListingId: this.$store.getters.listingStockID,
          CardNo: this.cardNumber.replace(/\s/g, ""),
          HolderName: this.name,
          ExpireYear: "20" + this.expiration.split("/")[1],
          ExpireMonth: this.expiration.split("/")[0],
          CVV: this.security
        });
        // window.location.href = response.data;
        await this.ListingDone();
        this.paid = true;
        this.completed = true;
        this.$store.dispatch("listingStockID", null);
      } catch (error) {
        this.errorMessage = error;
        this.$store.dispatch("showToast", {
          content: this.$t("Payment Error, Your credit card is invalid, please try again!"),
          type: "error",
        });

        console.log(error.data);
      }finally {
        this.payLoading = false;
      }
    },
    async ListingDone() {
      try {
        await axios.post("Listing/ListingDone", {
          ListingId: this.$store.getters.listingStockID
        });

        this.$router.push({ name: "ListingSuccess" });
      } catch (error) {
        console.log(error.data);
      }
    },
    async getListingPlan() {
      try {
        const response = await axios.get("Listing/GetPlans");
        this.plans = response.data

      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    getAmount(){
      if(this.plans[1]){
        return this.plans[1].Amount;
      }
      return 0
    },
    hasCompleted() {
      if (this.$store.getters.listingPlanID === 1 && this.completed) {
        return 1;
      } else if (this.$store.getters.listingPlanID === 2 && this.completed) {
        return 2;
      } else if (this.$store.getters.listingPlanID === 2) {
        return 3;
      } else return 0;
    },
    payable() {
      if (this.name && this.cardNumber && this.expiration && this.security)
        return false;
      else return true;
    }
  },
  beforeMount() {
    this.listingComplete();
    this.getListingPlan();
    this.listingStockID = this.$store.getters.listingStockID;
  },
  beforeDestroy() {
    //this.$store.dispatch("listingStockID", null);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.$store.getters.listingStockID) {
        vm.$router.push({ name: "Home" });
      }
    });
  }
};
</script>
<style lang="scss">
.success-card {
  height: 400px;
  background: white;
  border-radius: 5px;
  margin-top: 64px;
  margin-bottom: 64px;
}
.success-buttons-holder {
  padding-top: 43px;
  border-top: 1px solid #dbdbdb;
  padding-bottom: 64px;

}
.success-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    height: 40px;
    border-radius: 40px;
    border: 1px solid #006ac3;
    background: none;
    transition: 0.3s;
    padding: 0 15px;
    color: #006ac3;
    line-height: 2.5rem;
    &:hover {
      background: #00559d;
      color: white;
    }
  }
}
.pay-alert {
  margin: 64px 0 !important;
  padding: 20px 0 !important;
  min-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #111111 !important;
  font-family: "Inter", sans-serif;
  p {
    font-weight: 400;
  }
  h5{
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    color: #111111 !important;
    font-family: "poppins", sans-serif !important;

  }
}
.alert-box {
  text-align: center;
  .close {
    position: absolute;
    right: 40px;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    top: 50% !important;
    transform: translateY(-50%);
    font-size: 40px;
    font-weight: 200;
    color: #111;
    opacity: 1;
  }
}
.reserve-success-text {
  font-weight: 600 !important;
  font-family: "poppins", sans-serif !important;
  font-size: 40px !important;
  max-width: 800px !important;
  text-align: center !important;
  line-height: 56px !important;
}
.success-card {
  height: 400px;
  background: white;
  border-radius: 5px;
  margin-top: 64px;
  margin-bottom: 64px;

}
.reserve-success-sub-text {
  font-size: 20px;
  line-height: 30px;
  font-family: "Inter", sans-serif !important;
  font-weight: 400;
  margin-top: 5px;
}

</style>